import { ContentfulComponentForm, ContentfulComponentHero } from 'graphqlTypes';
import LayoutSection from 'layouts/LayoutSection';

import Flex from 'quarks/Flex';

import Section from 'molecules/Section/Section';

import Body from 'components/ResourceDetailLayout/components/Body';
import ConversionPanel from 'components/ResourceDetailLayout/components/ConversionPanel';
import Sidebar from 'components/ResourceDetailLayout/components/Sidebar/Sidebar';
import { getComponentProps } from 'components/ResourceDetailLayout/components/util';

import type { FC } from 'react';

type TemplateDetailLayoutProps = {
  heroSection: ContentfulComponentHero;
  template: {
    body: string;
    form: ContentfulComponentForm;
  };
};

const TemplateDetailLayout: FC<TemplateDetailLayoutProps> = props => {
  const { sidebarProps, conversionPanelProps } = getComponentProps(props) || {};
  const { tocProps } = sidebarProps || {};
  const { heroSection, template } = props;
  const { body, form, pdf } = template;

  const heroProps = {
    ...heroSection,
    displayBreadcrumbs: true,
    component: { ...heroSection.component, kicker: heroSection.component.heading, heading: template.title },
  };

  return (
    <Flex flexDirection="column">
      <LayoutSection {...heroProps} />
      {body && (
        <Section
          paddingTopDesktop="96"
          paddingBottomDesktop="96"
          paddingTopTablet="64"
          paddingBottomTablet="64"
          paddingTopMobile="48"
          paddingBottomMobile="48"
        >
          <Flex
            flexDirection="column-reverse"
            justifyContent="space-between"
            width="100%"
            gap="48px"
            lg={{ flexDirection: 'row' }}
            css={`
              .sidebar {
                position: relative;
                top: 0;
              }

              .resource-body {
                max-width: 100%;
              }

              .sidebar-demo a {
                margin: 0;
              }

              @media (min-width: 992px) {
                .sidebar {
                  max-width: 375px;
                }
              }
            `}
          >
            <Body body={body} author={heroProps.author} />
            <Sidebar tocProps={tocProps} customForm={{ ...form, assetData: pdf }} showGetDemo />
          </Flex>
        </Section>
      )}
      <ConversionPanel {...conversionPanelProps} />
    </Flex>
  );
};
export default TemplateDetailLayout;
