import { PageProps, graphql } from 'gatsby';
import { ContentfulComponentHero } from 'graphqlTypes';
import Layout from 'layouts/Layout';

import TemplateDetailLayout from 'components/TemplateHub/TemplateDetailLayout';

import type { FC } from 'react';

type TemplateProps = {
  templateData: any;
  heroSection: ContentfulComponentHero;
};

const Page: FC<PageProps<TemplateProps>> = ({ data: { templateData, heroSection }, location }) => (
  <Layout slug={location.pathname} seo={templateData.seo}>
    <TemplateDetailLayout heroSection={heroSection} template={templateData} />
  </Layout>
);

export default Page;

export const pageQuery = graphql`
  query templateTemplatesQuery($slug: String!) {
    templateData: contentfulTemplateTemplates(slug: { eq: $slug }) {
      ...contentfulTemplateTemplates
    }
    heroSection: contentfulLayoutSection(contentful_id: { eq: "7oUVi8Zlyznhs6KfB0jUaU" }) {
      ...contentfulLayoutSection
    }
  }
`;
